<template>
  <b-card>
    <cities-table
      :cities="mappedCities"
      :loading="loading"
      show-button
      show-search
      @button-click="onNewCityButtonClicked"
      @edit-city-click="onEditCityClicked"
      @city-deleted="onCityDeleted"
      @delete-city-error="onDeleteCityError"
    />
    <!-- ADD CITY MODAL -->
    <b-modal
      :id="'add-city-modal'"
      title="Afegeix un nou poble"
      scrollable
      hide-footer
    >
      <city-form
        @cancel="onAddCityCancel"
        @city-added="onCityAdded"
        @add-city-error="onAddCityError"
      />
    </b-modal>

    <!-- EDIT CITY MODAL -->
    <b-modal
      :id="'edit-city-modal'"
      title="Edita el poble"
      scrollable
      hide-footer
    >
      <city-form
        :city="selectedCity"
        @cancel="onEditCityCancel"
        @city-edited="onCityEdited"
        @edit-city-error="onEditCityError"
      />
    </b-modal>
  </b-card>
</template>

<script>
import CitiesTable from "@/views/cities/list/components/CitiesTable.vue";
import CityForm from "@/views/cities/list/components/CityForm.vue";
import { notifyError, notifySuccess } from "@/utils/methods";
import { BCard, BModal } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BModal,
    CitiesTable,
    CityForm,
  },
  data() {
    return {
      selectedCity: null,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["cities/loading"];
    },
    cities() {
      return this.$store.getters["cities/cities"];
    },
    mappedCities() {
      return this.mapTableCitiesArray(this.cities);
    },
  },
  created() {
    if (this.cities.length === 0 && !this.loading) this.fetchCities();
  },
  methods: {
    fetchCities() {
      this.$store
        .dispatch("cities/fetchCities", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchCities.title"),
            this.$t("errors.fetchCities.description")
          )
        );
    },
    mapTableCitiesArray(cities) {
      return cities.map((city) => ({
        uuid: city.uuid || "No definit",
        name: city.name || "No definit",
        slug: city.slug || "No definit",
      }));
    },
    onNewCityButtonClicked() {
      this.$bvModal.show("add-city-modal");
    },
    onEditCityClicked(cityUuid) {
      this.selectedCity = this.cities.find((city) => city.uuid === cityUuid);
      this.$bvModal.show("edit-city-modal");
    },
    onAddCityCancel() {
      this.$bvModal.hide("add-city-modal");
    },
    onCityAdded() {
      this.fetchCities();
      this.$bvModal.hide("add-city-modal");
      notifySuccess("Poble afegit", "El poble ha estat afegit correctament");
    },
    onAddCityError() {
      notifyError("Error", "Hi ha hagut un error al intentar afegir el poble");
    },
    onEditCityCancel() {
      this.$bvModal.hide("edit-city-modal");
    },
    onCityEdited() {
      this.fetchCities();
      this.$bvModal.hide("edit-city-modal");
      notifySuccess("Poble editat", "El poble ha estat editat correctament");
    },
    onEditCityError() {
      notifyError("Error", "Hi ha hagut un error al intentar editar el poble");
    },
    onCityDeleted() {
      this.fetchCities();
      notifySuccess(
        "Poble eliminat",
        "El poble ha estat eliminat correctament"
      );
    },
    onDeleteCityError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar eliminar el poble"
      );
    },
  },
};
</script>

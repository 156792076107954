<template>
  <div>
    <!-- NAME -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Nom</h6>
      </b-col>
      <b-col cols="12">
        <b-form-input v-model="name" autocomplete="off" />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BFormInput } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
  },
  props: {
    city: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      name: null,
    };
  },
  created() {
    if (this.city) this.initForm();
  },
  methods: {
    initForm() {
      this.name = this.city.name;
    },
    resetForm() {
      this.name = null;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      if (this.city) this.updateCity();
      else this.createCity();
    },
    createCity() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("cities/addCity", { name: this.name })
          .then(() => this.$emit("city-added"))
          .catch(() => this.$emit("add-city-error"))
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    updateCity() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("cities/updateCity", {
            uuid: this.city.uuid,
            name: this.name,
          })
          .then(() => this.$emit("city-edited"))
          .catch(() => this.$emit("edit-city-error"))
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
  },
};
</script>
